import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header: React.FC = () => {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);

  const isActive = (path: string) => {
    return location.pathname === path ? 'text-primary-700 font-semibold' : 'text-neutral-600 hover:text-primary-600';
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial state

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`bg-white py-4 transition-all duration-300 ${isSticky ? 'fixed top-0 left-0 right-0 shadow-md z-50' : ''}`}>
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold text-primary-700">
          ResumeTuner
        </Link>
        <div className="flex items-center space-x-8">
          <nav className="hidden md:flex space-x-8">
            <Link to="/" className={`${isActive('/')} transition duration-200 relative group`}>
              Features
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-primary-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
            </Link>
            <Link to="/pricing" className={`${isActive('/pricing')} transition duration-200 relative group`}>
              Pricing
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-primary-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
            </Link>
            <Link to="/how-it-works" className={`${isActive('/how-it-works')} transition duration-200 relative group`}>
              How it Works
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-primary-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
            </Link>
            <Link to="/login" className={`${isActive('/login')} transition duration-200 relative group`}>
              Sign In
              <span className="absolute left-0 bottom-0 w-full h-0.5 bg-primary-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
            </Link>
          </nav>
          <Link 
            to="/signup" 
            className="bg-primary-600 text-white font-semibold py-2 px-6 rounded-full hover:bg-primary-700 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Get Started
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
