import React from 'react';
import { Link } from 'react-router-dom';

interface HeroSectionProps {
  headline: string;
  subheading: string;
  ctaText: string;
  ctaLink: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ headline, subheading, ctaText, ctaLink }) => {
  return (
    <section className="bg-gradient-to-r from-primary-700 to-primary-800 py-40 text-white relative overflow-hidden">
      {/* Darker blue gradient overlay */}
      <div className="absolute inset-x-0 top-0 h-64 bg-gradient-to-b from-primary-900 to-transparent opacity-30 z-10"></div>
      
      {/* Animated wavy background */}
      <div className="absolute inset-0">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>

      <div className="container mx-auto px-4 text-center relative z-20">
        <h1 className="text-4xl md:text-5xl font-extrabold mb-4 leading-tight">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-white to-primary-100 animate-text-shine">
            {headline}
          </span>
        </h1>
        <h2 className="text-xl md:text-2xl mb-8 text-primary-100 font-light italic">
          {subheading}
        </h2>
        <Link 
          to={ctaLink}
          className="bg-accent hover:bg-accent-dark text-white font-bold py-3 px-8 rounded-full text-lg inline-block transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
          {ctaText}
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
