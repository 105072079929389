import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import HeroSection from './HeroSection';

const Pricing: React.FC = () => {
  const [activeFaq, setActiveFaq] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  const plans = [
    {
      name: 'Free',
      price: 'Free',
      features: [
        '1 Resume Tailoring per Month',
        'Basic AI Suggestions',
        'Resume Upload (PDF/DOCX)',
      ],
      cta: 'Start for Free',
    },
    {
      name: 'Professional',
      price: '$9.99/month',
      features: [
        'Unlimited Resume Tailoring',
        'Advanced AI Suggestions',
        'Real-Time Resume Preview',
        'Access to Job Description Scraping',
      ],
      cta: 'Get Professional',
    },
    {
      name: 'Career Coach',
      price: '$29.99/month',
      features: [
        'Tailor Resumes for Multiple Clients',
        'All Professional Features',
        'Client Management Tools',
        'Priority Support',
      ],
      cta: 'Get Career Coach',
    },
  ];

  const faqs = [
    {
      question: 'What is included in the Free Plan?',
      answer: 'The Free Plan includes one resume tailoring per month, basic AI suggestions, and resume uploads in PDF or DOCX format.',
    },
    {
      question: 'Can I upgrade or downgrade my plan at any time?',
      answer: 'Yes! You can switch plans at any time to suit your needs.',
    },
    {
      question: 'Is there a money-back guarantee?',
      answer: "If you're not satisfied with ResumeTuner, we offer a 30-day money-back guarantee for paid plans.",
    },
  ];

  return (
    <div className="min-h-screen bg-white font-sans flex flex-col">
      <Header />

      <main className="flex-grow">
        <HeroSection
          headline="Find the Perfect Plan for You"
          subheading="Whether you're a job seeker or a career coach, ResumeTuner has a plan that fits your needs."
          ctaText="Get Started"
          ctaLink="/signup"
        />

        {/* Pricing Plans Section with gradient background */}
        <section className="py-20 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-white via-white to-blue-50" style={{ height: '100px' }}></div>
          <div className="absolute inset-0 bg-blue-50" style={{ top: '100px' }}></div>
          <div className="px-4 max-w-7xl mx-auto relative z-10">
            <h2 className="text-3xl font-bold text-center mb-4">Choose Your Plan</h2>
            <p className="text-xl text-center text-gray-600 mb-20">Flexible pricing to suit job seekers, career shifters, and coaches.</p>
            <div className="flex justify-center">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-5xl">
                {plans.map((plan, index) => (
                  <Link
                    key={index}
                    to="/signup"
                    className={`flex ${index === 1 ? 'md:-mt-8' : ''} group`}
                  >
                    <div className={`p-8 pb-8 rounded-lg shadow-md flex flex-col w-full relative ${
                      index === 1 ? 'md:scale-120' : 'md:scale-90'
                    } bg-gradient-to-br from-white via-white to-gray-50 before:content-[''] before:absolute before:inset-0 before:bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iMzAwIj48ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIj48ZmVUdXJidWxlbmNlIGJhc2VGcmVxdWVuY3k9Ii43NSIgc3RpdGNoVGlsZXM9InN0aXRjaCIgdHlwZT0iZnJhY3RhbE5vaXNlIi8+PGZlQ29sb3JNYXRyaXggdHlwZT0ic2F0dXJhdGUiIHZhbHVlcz0iMCIvPjwvZmlsdGVyPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbHRlcj0idXJsKCNhKSIgb3BhY2l0eT0iLjA1Ii8+PC9zdmc+')] before:opacity-10 before:mix-blend-overlay before:rounded-lg overflow-hidden transition-all duration-300 ease-in-out group-hover:shadow-xl ${
                      index === 1 ? 'group-hover:[transform:scale(1.2)]' : 'group-hover:scale-100'
                    } group-hover:z-20 hover:delay-75`}>
                      {index === 1 && (
                        <div className="absolute top-0 right-0 overflow-hidden w-40 h-40">
                          <div className="absolute top-0 right-0 mt-5 -mr-10 w-40 h-8 bg-yellow-400 shadow-md transform rotate-45">
                            <span className="absolute bottom-1 left-0 right-0 text-center text-black text-xs font-bold py-1">
                              Most Popular
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="flex-grow relative z-10">
                        <h3 className="text-2xl font-bold text-primary-600 mb-4">{plan.name}</h3>
                        <p className="text-3xl font-bold text-accent mb-6">{plan.price}</p>
                        <ul className="mb-8">
                          {plan.features.map((feature, fIndex) => (
                            <li key={fIndex} className="flex items-center mb-2">
                              <svg className="w-5 h-5 mr-2 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                              </svg>
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="block w-full text-center bg-accent group-hover:bg-accent-dark text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out">
                        {plan.cta}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Rest of the component remains unchanged */}
      </main>

      <Footer />
    </div>
  );
};

export default Pricing;
