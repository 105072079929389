import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import LandingPage from './components/LandingPage';
import FeaturesPage from './components/FeaturesPage';
import ResumeUpload from './components/ResumeUpload';
import ResultsPage from './components/ResultsPage';
import Login from './components/Login';
import Signup from './components/Signup';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './components/Dashboard';
import JobDescription from './components/JobDescription';
import Pricing from './components/Pricing';
import HowItWorks from './components/HowItWorks';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-neutral-50">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/features" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/register" element={<Register />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            
            <Route path="/upload" element={
              <PrivateRoute>
                <ResumeUpload />
              </PrivateRoute>
            } />
            <Route path="/results" element={
              <PrivateRoute>
                <ResultsPage />
              </PrivateRoute>
            } />
            <Route path="/dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/job-description" element={
              <PrivateRoute>
                <JobDescription />
              </PrivateRoute>
            } />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;