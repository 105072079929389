import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getUserResumes } from '../services/api';

interface Resume {
  resumeId: string;
  title: string;
  createdAt: string;
}

const Dashboard: React.FC = () => {
  const [resumes, setResumes] = useState<Resume[]>([]);

  useEffect(() => {
    const fetchResumes = async () => {
      try {
        const fetchedResumes = await getUserResumes();
        setResumes(fetchedResumes);
      } catch (error) {
        console.error('Error fetching resumes:', error);
      }
    };

    fetchResumes();
  }, []);

  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-5">Your Resumes</h1>
      <Link to="/upload" className="bg-blue-500 text-white px-4 py-2 rounded mb-5 inline-block">
        Upload New Resume
      </Link>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {resumes.map((resume) => (
          <div key={resume.resumeId} className="border p-4 rounded shadow">
            <h2 className="text-xl font-semibold">{resume.title}</h2>
            <p className="text-gray-500">{new Date(resume.createdAt).toLocaleDateString()}</p>
            <Link to={`/tailor/${resume.resumeId}`} className="text-blue-500 mt-2 inline-block">
              Tailor Resume
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
