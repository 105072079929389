import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer className="bg-neutral-800 text-neutral-200 py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <img src="/logo-white.svg" alt="ResumeTuner Logo" className="h-8 mb-4" />
            <p>&copy; 2024 ResumeTuner. All Rights Reserved.</p>
          </div>
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Links</h3>
            <ul className="space-y-2">
              <li><a href="/#features" className="hover:text-primary-300 transition duration-200">Features</a></li>
              <li><Link to="/pricing" className="hover:text-primary-300 transition duration-200">Pricing</Link></li>
              <li><Link to="/how-it-works" className="hover:text-primary-300 transition duration-200">How it Works</Link></li>
              <li><Link to="/contact" className="hover:text-primary-300 transition duration-200">Contact Us</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="hover:text-primary-300 transition duration-200">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-primary-300 transition duration-200">Terms of Service</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/4">
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="text-neutral-200 hover:text-primary-300 transition duration-200">
                {/* LinkedIn icon */}
              </a>
              <a href="#" className="text-neutral-200 hover:text-primary-300 transition duration-200">
                {/* Facebook icon */}
              </a>
              <a href="#" className="text-neutral-200 hover:text-primary-300 transition duration-200">
                {/* Twitter icon */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
