import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

interface ResultsPageProps {
  originalResume: string;
  jobDescription: string;
  tailoredSuggestions: string;
}

const ResultsPage: React.FC = () => {
  const location = useLocation();
  const state = location.state as ResultsPageProps;

  if (!state) {
    return <Navigate to="/upload" replace />;
  }

  const { originalResume, jobDescription, tailoredSuggestions } = state;

  const formatSuggestions = (suggestions: string) => {
    const sections = suggestions.split(/\d+\./g).filter(Boolean);
    return sections.map((section, index) => (
      <div key={index} className="mb-4">
        <h4 className="font-semibold">{['Skills Alignment', 'Experience Relevance', 'Keywords', 'Missing Qualifications'][index]}</h4>
        <p>{section.trim()}</p>
      </div>
    ));
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Resume Tailoring Results</h2>
      <div className="grid grid-cols-2 gap-6">
        <div>
          <h3 className="text-xl font-semibold mb-2">Original Resume</h3>
          <pre className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap text-sm">{originalResume}</pre>
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-2">Job Description</h3>
          <pre className="bg-gray-100 p-4 rounded-md whitespace-pre-wrap text-sm">{jobDescription}</pre>
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-2">Tailored Suggestions</h3>
        <div className="bg-gray-100 p-4 rounded-md">
          {formatSuggestions(tailoredSuggestions)}
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
