import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { submitJobDescription } from '../services/api';

const JobDescription: React.FC = () => {
  const [jobDescription, setJobDescription] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const result = await submitJobDescription(jobDescription);
      navigate('/tailor-results', { state: { jobDescription, parsedJob: result } });
    } catch (error) {
      console.error('Error submitting job description:', error);
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-5">Enter Job Description</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          className="w-full h-64 p-2 border rounded"
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          placeholder="Paste the job description here..."
          required
        />
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
        >
          Analyze Job Description
        </button>
      </form>
    </div>
  );
};

export default JobDescription;
