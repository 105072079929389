import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './LandingPage.css'; // Import the new CSS file
import HeroSection from './HeroSection';

// Define an interface for the headline object
interface Headline {
  headline: string;
  subheading: string;
}

const LandingPage: React.FC = () => {
  const [activeFeature, setActiveFeature] = useState<number | null>(null);

  const toggleFeature = (index: number) => {
    setActiveFeature(activeFeature === index ? null : index);
  };

  const features = [
    {
      title: 'AI-Powered Resume Magic',
      description: 'Let our AI be your personal resume wizard. It analyzes job descriptions and your experience to suggest killer improvements.',
      icon: '🧠',
      expandedDescription: `Our smart AI digs deep into both your resume and the job listing. It spots gaps, suggests trending keywords, and highlights areas where you can level up. By aligning your resume with what employers want, you\'ll breeze through those pesky Applicant Tracking Systems (ATS) like a pro.`,
    },
    {
      title: 'Real-Time Resume Glow-Up',
      description: 'Watch your resume transform in real-time as you apply edits. It\'s like having a resume makeover, but way faster!',
      icon: '✨',
      expandedDescription: `See your changes come to life instantly with our real-time preview feature. No need to download or refresh constantly—everything updates live, so you can craft the perfect resume in record time.`,
    },
    {
      title: 'One-Click Job Description Analysis',
      description: 'Found a dream job? Just paste the URL, and we\'ll extract all the important details to tailor your resume automatically.',
      icon: '🔍',
      expandedDescription: `Simply upload your resume (PDF or DOCX) and drop in the job listing URL. Our smart scraper will pull out the most relevant info, helping you match your resume to the job posting with minimal effort.`,
    },
    {
      title: 'Resume Version Control',
      description: 'Keep track of all your resume versions effortlessly. It\'s like GitHub for your job applications!',
      icon: '🔄',
      expandedDescription: `Juggle multiple resume versions like a pro. Create, edit, and store different resumes for various roles or industries. Never send the wrong version again!`,
    },
    {
      title: 'Fort Knox for Your Data',
      description: 'Your info is locked down tight. We use top-notch security to keep your personal details safe and sound.',
      icon: '🔒',
      expandedDescription: `We take your privacy seriously. Your data is encrypted with state-of-the-art technology, and we follow strict security protocols. Your personal info stays yours, period.`,
    },
  ];

  const personas = [
    {
      title: 'New Graduate',
      description: 'Just starting out? ResumeTuner helps you craft a professional resume with minimal experience, highlighting your skills and achievements.',
      icon: '🎓',
    },
    {
      title: 'Career Shifter',
      description: 'Transitioning to a new industry? Use ResumeTuner to shift the focus of your resume to emphasize transferable skills and relevant experience.',
      icon: '💼',
    },
    {
      title: 'Job Coach',
      description: 'As a coach, you can help your clients optimize their resumes with tailored suggestions for each job posting.',
      icon: '📝',
    },
  ];

  const landingPageHeadlines: Headline[] = [
    {
      headline: "Land Your Dream Job Faster with AI-Powered Resumes",
      subheading: "Instantly customize your resume to fit any job description and get noticed by employers."
    },
    {
      headline: "Unlock More Job Opportunities with AI-Tailored Resumes",
      subheading: "Automatically tailor your resume for each job—because a one-size-fits-all resume won't cut it."
    },
    {
      headline: "Stop Sending Generic Resumes. Start Getting Interviews.",
      subheading: "Let AI help you craft the perfect resume for every job application."
    },
    {
      headline: "AI Tailoring for Job-Winning Resumes",
      subheading: "Create custom resumes for every job in seconds and leave the competition behind."
    },
    {
      headline: "Supercharge Your Resume in Minutes with AI",
      subheading: "Get instant, tailored recommendations to match any job description. Faster applications, better results."
    },
    {
      headline: "Your Shortcut to the Perfect Resume",
      subheading: "Just upload, let AI optimize, and watch the interview offers roll in."
    },
    {
      headline: "Get Noticed with an AI-Optimized Resume",
      subheading: "Quickly tailor your resume to fit any job. Maximize your chances of landing an interview."
    },
    {
      headline: "Transform Your Resume in Seconds with AI",
      subheading: "Don't waste time tweaking—get automatic suggestions to match your resume with the perfect job."
    },
    {
      headline: "Make Your Resume Pop with AI",
      subheading: "Custom-fit your resume to every job with AI suggestions. It's like having a personal career coach!"
    },
    {
      headline: "Ace Every Job Application with AI Resume Magic",
      subheading: "Tailor your resume for the job you want with just a few clicks. Let AI do the hard work for you."
    },
    {
      headline: "Crush Your Job Search with AI-Optimized Resumes",
      subheading: "Tailor your resume for each role and boost your chances of landing an interview."
    },
    {
      headline: "One Resume. Many Jobs. Tailored Instantly with AI.",
      subheading: "Get personalized resume edits for every job posting and increase your chances of getting hired."
    },
    {
      headline: "AI Tailoring for the Perfect Resume Fit",
      subheading: "Quickly adjust your resume for each job and maximize your interview chances."
    },
    {
      headline: "Job-Specific Resumes in Seconds with AI",
      subheading: "No more guesswork—let AI help you tailor your resume to every job posting."
    }
  ];

  const [currentHeadline, setCurrentHeadline] = useState<Headline>(landingPageHeadlines[0]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * landingPageHeadlines.length);
    setCurrentHeadline(landingPageHeadlines[randomIndex]);
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 font-sans flex flex-col">
      <Header />

      <main className="flex-grow">
        <HeroSection
          headline={currentHeadline.headline}
          subheading={currentHeadline.subheading}
          ctaText="Get Started"
          ctaLink="/signup"
        />

        {/* Updated Features Overview Section */}
        <section id="features" className="pt-24 pb-20 bg-gradient-to-b from-white via-gray-50 to-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-4">Powerful Tools to Optimize Your Resume</h2>
            <p className="text-xl text-center text-gray-600 mb-12">Supercharge your job hunt with these game-changing features.</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 justify-items-center">
              {features.map((feature, index) => (
                <div key={index} className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 overflow-hidden">
                  <div className="p-8">
                    <div className="text-4xl mb-4 bg-primary-100 rounded-full w-16 h-16 flex items-center justify-center">{feature.icon}</div>
                    <h3 className="text-xl font-semibold mb-2 text-primary-600">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* User Personas Section */}
        <section className="bg-gray-100 py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-4">ResumeTuner for Every Type of Job Seeker</h2>
            <p className="text-xl text-center text-gray-600 mb-12">No matter where you are in your career, ResumeTuner can help.</p>
            <div className="grid md:grid-cols-3 gap-8">
              {personas.map((persona, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                  <div className="text-4xl mb-4">{persona.icon}</div>
                  <h3 className="text-xl font-semibold mb-2">{persona.title}</h3>
                  <p className="text-gray-600">{persona.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* How it Works Section */}
        <section id="how-it-works" className="py-20">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-4">How ResumeTuner Works</h2>
            <p className="text-xl text-center text-gray-600 mb-12">Discover how our features can enhance your resume and boost your job search.</p>
            <div className="space-y-4">
              {features.map((feature, index) => (
                <div key={index} className="border-b border-gray-200 pb-4">
                  <button
                    className="flex justify-between items-center w-full text-left focus:outline-none"
                    onClick={() => toggleFeature(index)}
                  >
                    <span className="text-lg font-semibold text-primary-600">{feature.title}</span>
                    <svg
                      className={`w-6 h-6 text-primary-600 transform transition-transform duration-300 ${
                        activeFeature === index ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </button>
                  <div
                    className={`mt-2 text-gray-600 overflow-hidden transition-all duration-300 ease-in-out ${
                      activeFeature === index ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                    }`}
                  >
                    <p>{feature.expandedDescription}</p>
                    {/* Here you would add the visual demo or video for each feature */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="bg-gradient-to-r from-primary-600 to-primary-700 py-20 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Optimize Your Resume?</h2>
            <p className="text-xl mb-8">Join thousands of job seekers and start tailoring your resume today.</p>
            <Link 
              to="/signup" 
              className="bg-accent hover:bg-accent-dark text-white font-bold py-3 px-8 rounded-full text-lg inline-block transition duration-300 ease-in-out transform hover:scale-105"
            >
              Get Started
            </Link>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default LandingPage;
