import axios from 'axios';

const API_BASE_URL = 'http://localhost:3000';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export const uploadResume = async (file: File, jobDescription: string) => {
  const formData = new FormData();
  formData.append('resume', file);
  formData.append('jobDescription', jobDescription);

  const response = await api.post('/upload-resume', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const submitJobDescription = async (jobDescription: string) => {
  const response = await api.post('/job-description', { jobDescription });
  return response.data;
};

export const getTailoringResults = async (resumeId: string, jobDescriptionId: string) => {
  const response = await api.post('/tailor-resume', { resumeId, jobDescriptionId });
  return response.data;
};

export const getUserResumes = async () => {
  const response = await api.get('/resumes');
  return response.data;
};

export const registerUser = async (email: string, password: string) => {
  const response = await api.post('/register', { email, password });
  return response.data;
};

export const loginUser = async (email: string, password: string) => {
  const response = await api.post('/login', { email, password });
  return response.data;
};
