import React from 'react';
import Header from './Header';
import Footer from './Footer';
import HeroSection from './HeroSection';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      title: "Upload Your Resume",
      description: "Start by uploading your resume in PDF or DOCX format. ResumeTuner's easy-to-use drag-and-drop feature makes it effortless.",
      icon: "📄"
    },
    {
      title: "Paste the Job Description",
      description: "Next, paste the job description or URL of the job posting. ResumeTuner will scrape the job listing and analyze the key requirements.",
      icon: "🔍"
    },
    {
      title: "Get AI-Powered Suggestions",
      description: "Finally, receive tailored AI suggestions that will enhance your resume to better match the job description. You can accept or reject each recommendation.",
      icon: "🧠"
    }
  ];

  const faqs = [
    {
      question: "How does ResumeTuner improve my resume?",
      answer: "ResumeTuner uses AI to analyze your resume and compare it to the job description. This allows us to provide tailored suggestions to make your resume stand out."
    },
    {
      question: "What file formats does ResumeTuner accept?",
      answer: "We accept PDF and DOCX formats, ensuring that your resume maintains its formatting."
    },
    {
      question: "Can I try ResumeTuner for free?",
      answer: "Yes! You can try ResumeTuner with our free plan, which allows you to tailor one resume per month."
    }
  ];

  const createWavePath = () => {
    const width = 1000;
    const height = 100;
    const waveLength = width / 2;
    const amplitude = height / 2;
    
    let path = `M 0 ${height} `;
    for (let x = 0; x <= width; x++) {
      const y1 = Math.sin((x / waveLength) * 2 * Math.PI) * amplitude;
      const y2 = Math.sin((x / (waveLength * 0.8) + 0.1) * 2 * Math.PI) * (amplitude * 0.5);
      const y = height - (y1 + y2 + height) / 2;
      path += `L ${x} ${y} `;
    }
    path += `L ${width} ${height} Z`;
    return path;
  };

  return (
    <div className="min-h-screen bg-gray-100 font-sans flex flex-col">
      <Header />
      <main className="flex-grow">
        <HeroSection
          headline="How ResumeTuner Works"
          subheading="Tailor your resume in just three simple steps with AI-powered precision."
          ctaText="Get Started"
          ctaLink="/signup"
        />

        {/* Steps Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">How It Works: 3 Easy Steps to Optimize Your Resume</h2>
            <p className="text-xl text-center mb-12 text-gray-600">Get started with ResumeTuner in no time—just follow these simple steps.</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {steps.map((step, index) => (
                <div key={index} className="bg-gray-50 p-8 rounded-lg shadow-lg text-center hover:shadow-xl transition duration-300">
                  <div className="text-5xl mb-4">{step.icon}</div>
                  <h3 className="text-primary text-2xl font-bold mb-3">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* White space between sections */}
        <div className="bg-white relative h-16 sm:h-24 md:h-32 lg:h-40"></div>
         {/* Top wave */}
        {/* Call to Action Section with top wave */}
        <section className="bg-primary text-white relative">
        <div className="absolute inset-0 from-primary-600/30 to-primary-800/30" style={{ marginTop: '-95px' }}></div>
            <div className="absolute inset-0" style={{
              backgroundImage: `radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px)`,
              backgroundSize: '20px 20px',
              marginTop: '-95px',
              zIndex: '30'
            }}>
                
            </div>
        <div className="absolute top-0 left-0 w-full overflow-hidden z-20" style={{ transform: 'translateY(-80%)' }}>
            <svg
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
              className="w-[200%] h-16 md:h-24"
              style={{ background: '' }}
            >
              <path
                d={createWavePath()}
                className="fill-current animate-wave from-primary-600/30 to-primary-800/30"
                style={{ transform: 'rotate(-180deg) scaleX(-2px)', fill: '#1e3a8a' }}
              ></path>
            </svg>
          </div>
          {/* Content with gradient overlay and dotted background */}
          <div className="relative">
            
            <div className="container mx-auto px-4 py-20 relative z-50">
              <h2 className="text-3xl md:text-4xl font-bold mb-4">Ready to Optimize Your Resume?</h2>
              <p className="text-xl mb-8">It's easy to get started. Upload your resume, paste the job description, and let ResumeTuner's AI do the rest.</p>
              <button className="bg-accent hover:bg-accent-dark text-white font-bold py-3 px-8 rounded-full text-lg transition duration-300 ease-in-out transform hover:scale-105">
                Get Started
              </button>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
            <div className="space-y-6 max-w-3xl mx-auto">
              {faqs.map((faq, index) => (
                <details key={index} className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                  <summary className="text-primary text-xl font-bold cursor-pointer">{faq.question}</summary>
                  <p className="mt-4 text-gray-600">{faq.answer}</p>
                </details>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HowItWorks;
